import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { shades, Section } from '@resident-advisor/design-system'
import { useServerTime } from 'context/ServerTimeContext'
import { getRelativeUri } from 'lib/utils'
import getYear from 'date-fns/getYear'
import addYears from 'date-fns/addYears'
import getConfig from 'next/config'
import EventListingCard from 'components/events/cards/event-listing'
import ErrorBoundary, { ErrorType } from 'components/generic/error-boundary'
import { useIntl } from 'react-intl'
import messages from 'messages/events'
import { linkPropTypes } from 'components/generic/link'
import ViewMoreButton from 'components/generic/view-more-button'
import Listing, {
  CreateListingArchivePagination,
} from 'components/shared/listing'

import YearlyArchiveWithEventsTracking from './YearlyArchiveWithEventsTracking'

const FIRST_EVENT_YEAR = 2001

const {
  publicRuntimeConfig: { YEARS_FILTER_CONFIG },
} = getConfig()

const FilteredEventsListing = ({
  id,
  name,
  baseFilterType,
  listingFilterRouteFormatter,
  createSubNav,
  includeCountryAggregation,
  YearlyArchiveSecondaryComponent,
  eventsListingType,
  archiveLinkRouteProps,
  backgroundColor,
}) => {
  const intl = useIntl()
  const { query, asPath } = useRouter()
  const serverTime = useServerTime()

  const getLinkParams = (path, queryParams) =>
    listingFilterRouteFormatter(getRelativeUri(path), queryParams)
  const formatUrl = ({ idOrCountry, slug, ...data }) =>
    getLinkParams(asPath, data)

  const baseFilters = [
    GetFilter(baseFilterType, serverTime, id),
    GetFilter(eventsListingType, serverTime, id),
  ]

  const aggregations = [Listing.Aggregations.Year]
  if (includeCountryAggregation) {
    aggregations.push(Listing.Aggregations.Country)
  }

  const defaultEndYear = parseInt(
    getYear(addYears(serverTime, YEARS_FILTER_CONFIG.BUFFER_FUTURE_YEARS))
  )

  let archiveTitle = query?.year
  if (archiveTitle?.endsWith('/')) {
    // Sometimes the year is being sent with a trailing '/' in the URL - so trim it here
    archiveTitle = archiveTitle.slice(0, -1)
  }

  return (
    <ErrorBoundary errorType={ErrorType.EventListingRender}>
      <Listing
        backgroundColor={backgroundColor}
        type={Listing.Type.Event}
        aggregations={aggregations}
        baseFilters={baseFilters}
        defaultFilters={[]}
        formatUrl={formatUrl}
        sortOrder={getSortOrder(query, eventsListingType)}
        sortField={Listing.SortField.EventDate}
        createSubNav={createSubNav}
        createContent={({ data }) => (
          <YearlyArchiveWithEventsTracking
            Card={(event) => <EventListingCard event={event} key={event.id} />}
            title={archiveTitle}
            data={data}
            SecondaryComponent={YearlyArchiveSecondaryComponent}
            secondaryComponentWrapBottom={!!YearlyArchiveSecondaryComponent}
            eventsListingType={eventsListingType}
            baseFilterType={baseFilterType}
            contentObjectId={id}
            contentObjectName={name}
          />
        )}
        createPagination={CreateListingArchivePagination(
          [...baseFilters.map((bf) => bf.type), Listing.Aggregations.Year],
          FIRST_EVENT_YEAR,
          defaultEndYear,
          {
            modes: { year: true },
            getLinkParams,
          },
          {
            shade: shades.dark,
          }
        )}
      />
      {eventsListingType === EventsListingType.Upcoming &&
        archiveLinkRouteProps && (
          <Section pb={3}>
            <ViewMoreButton {...archiveLinkRouteProps}>
              {intl.formatMessage(messages.viewPastEventsInstead)}
            </ViewMoreButton>
          </Section>
        )}
    </ErrorBoundary>
  )
}

const GetFilter = (filterType, serverTime, id) => {
  const now = new Date(serverTime).toISOString()
  switch (filterType) {
    case EventsListingType.Archive: {
      return {
        type: Listing.Aggregations.DateRange,
        value: JSON.stringify({ lt: now }),
      }
    }

    case EventsListingType.Upcoming:
      return {
        type: Listing.Aggregations.DateRange,
        value: JSON.stringify({ gte: now }),
      }

    default:
      return {
        type: filterType,
        value: id,
      }
  }
}

const getSortOrder = (query, eventsListingType) => {
  if (eventsListingType === EventsListingType.Upcoming) {
    // For non-archive always from most recent => future
    return Listing.SortOrder.Ascending
  }

  // For archive, if year filter then jan-dec (most recent => future)
  return query?.year
    ? Listing.SortOrder.Ascending
    : Listing.SortOrder.Descending
}

const EventsListingType = {
  Archive: 'Archive',
  Upcoming: 'Upcoming',
}

FilteredEventsListing.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  baseFilterType: PropTypes.oneOf([
    Listing.Aggregations.Artist,
    Listing.Aggregations.Club,
    Listing.Aggregations.Promoter,
  ]),

  listingFilterRouteFormatter: PropTypes.func.isRequired,
  createSubNav: PropTypes.func.isRequired,
  includeCountryAggregation: PropTypes.bool,
  YearlyArchiveSecondaryComponent: PropTypes.func,
  eventsListingType: PropTypes.oneOf(Object.values(EventsListingType)),
  archiveLinkRouteProps: linkPropTypes,
  backgroundColor: PropTypes.string,
}

export { EventsListingType }
export default FilteredEventsListing
