import PropTypes from 'prop-types'
import {
  Column,
  Grid,
  Layout,
  Section,
  Text,
  Box,
  variants,
} from '@resident-advisor/design-system'
import MonthlyList from 'components/generic/monthly-list'
import NoResults from 'components/generic/no-results'
import arrayHasData from 'lib/arrayHasData'
import Padded from 'components/generic/padded'

const YearlyArchive = ({
  Card,
  SecondaryComponent,
  Pagination,
  data,
  title,
  secondaryComponentWrapBottom,
  variant,
  TitleComponent,
  ...props
}) => {
  return (
    <>
      {arrayHasData(data) ? (
        <Padded>
          <Section variant={variant} {...props}>
            <Layout>
              <Grid
                sCols={1}
                mCols={3}
                sWrap
                gridTemplateAreas={{
                  s: getSGTA(secondaryComponentWrapBottom),
                  m: mGTA,
                }}
              >
                {(title || TitleComponent) && (
                  <Column area={Areas.Title}>
                    {TitleComponent ? (
                      <TitleComponent />
                    ) : (
                      <Text variant={variants.text.stat} color="secondary">
                        {title}
                      </Text>
                    )}
                  </Column>
                )}
                <Column area={Areas.Primary}>
                  <MonthlyList Card={Card} data={data} variant={variant} />
                </Column>
                <Column area={Areas.Secondary}>
                  {SecondaryComponent && (
                    <Box py={{ s: 0, m: 4 }}>
                      <SecondaryComponent />
                    </Box>
                  )}
                </Column>
              </Grid>
            </Layout>
          </Section>
        </Padded>
      ) : (
        <NoResults subSectionProps={{ variant: variants.section.secondary }} />
      )}
      {Pagination && <Pagination />}
    </>
  )
}

const Areas = {
  Title: 'title',
  Primary: 'primary',
  Secondary: 'secondary',
}

const getSGTA = (secondaryComponentWrapBottom) => {
  if (secondaryComponentWrapBottom) {
    return `
      "title"
      "primary"
      "secondary"
    `
  }
  return `
    "title"
    "secondary"
    "primary"
  `
}

const mGTA = `
  "title title ."
  "primary primary secondary"
`

YearlyArchive.propTypes = {
  Card: PropTypes.func,
  SecondaryComponent: PropTypes.func,
  Pagination: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  TitleComponent: PropTypes.func,
  secondaryComponentWrapBottom: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

YearlyArchive.defaultProps = {
  data: [],
  secondaryComponentWrapBottom: true,
  variant: variants.section.secondary,
}

export default YearlyArchive
